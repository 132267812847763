import React from 'react';
import PropTypes from "prop-types";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import InputMask from 'react-input-mask';
import _ from "lodash";

const DateMask = ({label, field, error, name, handleOnChange, handleOnBlur, innerRef, placeholder, autocomplete, readonly, children, value, required, mask}) => {
    return (

        <FormGroup>
            {
                label && <Label htmlFor={name}
                                className={` ${!_.isEmpty(error) ? 'is-invalid' : ''} `}> {label}
                    {
                        required && <span className={'field-required text-danger ml-1'}>*</span>
                    }</Label>
            }
            <Input
                {...{
                    ...field && field,
                    ...handleOnChange && {onChange: handleOnChange},
                    ...handleOnBlur && {onBlur: handleOnBlur},
                    ...value && value,
                    id: name,
                    innerRef,
                    type: "tel",
                    placeholder,
                    name,
                    invalid: !_.isEmpty(error),
                    autoComplete: autocomplete,
                    readOnly: readonly,
                    mask: mask || '99/99/9999',
                    maskChar: "_",
                    tag: InputMask,
                    'data-testid':`test-${name}`,
                    'data-hj-whitelist': 'data-hj-whitelist',
                }}
            >
                {children}
            </Input>
            {error && (<FormFeedback valid={_.isEmpty(error)}>{error}</FormFeedback>)}
        </FormGroup>
    );
};
DateMask.propTypes ={
    label: PropTypes.string,
    field: PropTypes.object,
    error: PropTypes.any,
    name: PropTypes.string,
    handleOnChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    readonly: PropTypes.bool,
    value: PropTypes.any
};
export default DateMask;