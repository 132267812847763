import React, {Component} from 'react';
import _ from 'lodash';
import {
  Button,
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Jumbotron,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions, {login} from '../../redux/actions/admin.actions';
import AdminLoginForm from './form.login';

export default function(ComposedComponent, _props) {

  class PageAuthorisedTest extends Component {
    state = {
      openNav: false,
    };
    toggleNav = () => this.setState(prevState => ({...prevState, openNav: !prevState.openNav}));

    _renderNav() {
      if (!this.isAuthorized()) return null;

      const {onLogout, login: {email}} = this.props;
      const {openNav} = this.state;
      const {toggleNav} = this;

      return (
          <Navbar color="light" light expand="md">
            <NavbarBrand href="/">Marketplace</NavbarBrand>
            <NavbarToggler onClick={toggleNav}/>
            <Collapse isOpen={openNav} navbar>
              <Nav className="mr-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                  <NavLink href="/#/form">Granite</NavLink>
                </UncontrolledDropdown>
                {
                  this.isAdmin() && <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Admin
                    </DropdownToggle>
                    <DropdownMenu left="true">
                      <DropdownItem>
                        <NavLink href="/#/admin/users">Users</NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                }

              </Nav>
              <Button outline color="secondary" onClick={onLogout}>Logout ({email})</Button>
            </Collapse>
          </Navbar>

      );
    }

    isAdmin() {
      const roles = _.get(this.props, 'login.roles', []);
      return roles.findIndex(r => r.role === 'admin') !== -1;
    }

    isAuthorized() {
      //todo check token expired
      const access_token = _.get(this.props, 'login.access_token');
      return access_token && access_token.length;
    }

    _renderLogin() {
      const {login: {email, errors, success_message}, onLogin, login, onNavigate} = this.props;

      return (
          <Jumbotron>
            {!_.isEmpty(errors) && <div className="alert alert-danger text-center">{_.isString(errors) ? errors : 'Please fix the errors below'}</div>}
            {success_message.length > 0 && <div className="alert alert-success text-center">{success_message}</div>}
            <AdminLoginForm {...{onLogin, login, onNavigate}} initialValues={{email}}/>
          </Jumbotron>
      );
    }

    render() {
      if (!this.isAuthorized()) return this._renderLogin();
      //this class isn't meant to be rendered by itself
      return <main>
        {this._renderNav()}
        <div className={'container pt-5'}>
          <ComposedComponent {...this.props} isAdmin={this.isAdmin()}/>
        </div>
      </main>;
    }
  }

  const mapStateToProps = (state) => {
    return {...state.admin, ..._props};

  };

  const mapDispatchToProps = (dispatch) => {
    const admin = bindActionCreators(actions, dispatch);
    return {
      onLogin: (data) => dispatch(login(data)),
      onLogout: () => dispatch(admin.logout),
      onNavigate: () => dispatch(admin.navigate),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(PageAuthorisedTest);
}
