import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import {HashRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//redux
import {Provider} from 'react-redux';
import {store, persistor, clearStorage} from './redux/configure.store';
//redux persist
import {PersistGate} from 'redux-persist/integration/react';

const reset = () => {
    persistor
        .purge()
        .then(() => persistor.flush())
        .then(() => {
            setTimeout(() => window.location.reload(), 100);
        });
};

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <PersistGate loading={null} onBeforeLift={clearStorage} persistor={persistor}>
                <App/>
            </PersistGate>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
