import React from 'react';
import Autosuggest from 'react-autosuggest';
import _ from "lodash";
import {FormFeedback, FormGroup, InputGroupAddon, InputGroup, Button, Input, Label} from 'reactstrap';
import PropTypes from "prop-types";

const getSuggestions = (value, options) => {
    if (value === "") return [];
    const _value = escapeRegExp(value);
    const regex = new RegExp(_value, 'i');
    return options.filter(opt => regex.test(opt.value))
};
const escapeRegExp = value => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const getSuggestionValue = suggestion => {
    return suggestion.value;
};


const renderSuggestion = suggestion => {
    return (
        <span>
            {suggestion.value}
        </span>
    );
};


class AutoSuggest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            value: props.value ? props.value : ''
        }
    }

    componentDidMount() {
        const {value, options} = this.props;
        if (value) {
            const option = options.filter(opt => opt.key === value)[0];
            if (option) {
                this.setState({value: option.value})
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {value: prevValue} = prevProps;
        const {value: currentValue, options} = this.props;

        if ( options && (!prevValue || prevValue) && currentValue && prevValue !== currentValue) {
            const option = options.filter(opt => opt.key === currentValue)[0];
            if (option) {
                this.setState({value: option.value})
            }
        }

    }

    onChange = (event, {newValue}) => {
        this.setState({value: newValue}, () => this.props.handleOnChange(newValue));
    };

    onSuggestionSelected = (event, {suggestionValue, suggestion, method}) => {
        if (method === 'enter') {
            event.preventDefault();
        }
        const {handleOnChange} = this.props;

        if (handleOnChange && typeof handleOnChange === "function") {
            if (this.props.handleOnChange) {
                this.setState({
                    value: suggestionValue
                }, () => handleOnChange(suggestion.key))
            }
        }

    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({value}) => {
        const {options} = this.props;
        this.setState({
            suggestions: getSuggestions(value, options)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    clearSuggestion = () => this.setState({value: ''});
    renderInputComponent = inputProps => {
        const {displayClearButton} = this.props;

        return (displayClearButton ?
                <InputGroup>
                    <InputGroupAddon addonType="prepend"><Button onClick={() => this.onChange(null, {newValue: ''})}>clear</Button></InputGroupAddon>
                    <Input {...inputProps} />
                </InputGroup>
                : <Input {...inputProps} />
        );
    };


    render() {
        const {suggestions, value} = this.state,
            {placeholder, autocomplete, name, error, label, innerRef, required, autofocus = false} = this.props;

        const inputProps = {
            value,
            onChange: this.onChange,
            autoComplete: autocomplete,
            placeholder,
            className: 'form-control',
            invalid: !_.isEmpty(error),
            autoFocus: autofocus,
            innerRef,
            'data-testid': `test-${name}`
        };


        return (
            <FormGroup>
                {
                    label && <Label htmlFor={name} className={` ${!_.isEmpty(error) ? 'is-invalid' : ''} `}>{label}{
                        required && <span className={'field-required text-danger ml-1'}>*</span>
                    }</Label>
                }
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderInputComponent={this.renderInputComponent}
                    inputProps={inputProps}

                />
                {error && (<FormFeedback style={{display: error ? 'block' : 'none'}}
                                         valid={_.isEmpty(error)}>{error}</FormFeedback>)}
            </FormGroup>

        );
    }
}

AutoSuggest.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    handleOnChange: PropTypes.func,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    displayClearButton: PropTypes.bool,
};
AutoSuggest.defaultProps = {
    placeholder: 'Please type to start searching...',
    displayClearButton: false

};

export default AutoSuggest;