import {handleActions} from 'redux-actions';
import actions from '../actions/vehicle.lookup.actions';
import api_actions from '../actions/api.actions';
import _ from 'lodash';

export default handleActions({
    [actions.vehicle_lookup_make]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    makes: [],
                    models: [],
                    years: [],
                    bodyTypes: [],
                    transmissions: [],
                    fuelTypes: [],
                    rawSpecs: [],
                    specs: [],
                    isLoading: true,
                    details:{}
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },

    [actions.vehicle_lookup_model]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    models: [],
                    years: [],
                    bodyTypes: [],
                    transmissions: [],
                    fuelTypes: [],
                    rawSpecs: [],
                    specs: [],
                    isLoading: true,
                    details:{}
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },

    [actions.vehicle_lookup_year]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    years: [],
                    bodyTypes: [],
                    transmissions: [],
                    fuelTypes: [],
                    rawSpecs: [],
                    specs: [],
                    isLoading: true,
                    details:{}
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },

    [actions.vehicle_lookup_body_type]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    bodyTypes: [],
                    transmissions: [],
                    fuelTypes: [],
                    rawSpecs: [],
                    specs: [],
                    details:{},
                    isLoading: true
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },
    [actions.vehicle_lookup_transmission]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    transmissions: [],
                    fuelTypes: [],
                    rawSpecs: [],
                    specs: [],
                    details:{},
                    isLoading: true
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },

    [actions.vehicle_lookup_fuel_type]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    fuelTypes: [],
                    rawSpecs: [],
                    specs: [],
                    details:{},
                    isLoading: true
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },
    [actions.vehicle_lookup_spec]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    errors: {},
                    rawSpecs: [],
                    specs: [],
                    details:{},
                    isLoading: true
                };
            case api_actions.api_end:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },


    [actions.vehicle_lookup_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');
        return {...state, errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message')};
    },
    [actions.vehicle_lookup_succeeded]: (state, action) => {
        return {...state, ...action.payload}
    }
}, getDefaults());

export function getDefaults() {
    return {
        errors: {},
        makes: [],
        models: [],
        years: [],
        bodyTypes: [],
        transmissions: [],
        fuelTypes: [],
        rawSpecs: [],
        specs: [],
        isLoading: false,
        details: {}
    };
}

const mapVehicleDetails = data => {
    const {make: vehicleMake, model: vehicleModel, year: yearOfManufacture, cc: vehicleCC, abi_code: abiCode, fuelType, transmission: gearboxType, seats: numberOfSeats, doors: numberOfDoors,} = _.pick(data, ['make', 'model', 'year', 'cc', 'abi_code', 'fuelType', 'transmission', 'seats', 'doors']);
    return {
        vehicleMake,
        vehicleModel,
        yearOfManufacture,
        vehicleCC,
        abiCode,
        fuelType,
        gearboxType,
        numberOfSeats,
        numberOfDoors
    }
}
export const handleUpdateVehicleDetailsManually = (state, action) => {
    const {uuid, ...rest} = action.payload;
    if (!uuid) return state;

    return {
        ...state,
        form: {
            ...state.form,
            [uuid]: {
                ...state.form[uuid],
                data: {
                    ...state.form[uuid].data,
                    motorRiskQueryVehicle:{
                        ...state.form[uuid].data.motorRiskQueryVehicle,
                        ...mapVehicleDetails(rest)
                    }
                }
            }
        }
    }
}