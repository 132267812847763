import { handleActions } from 'redux-actions';
import actions from '../actions/admin.actions';
import { API_END, API_LOADING, API_READY, API_START } from '../constants/api.constants';
import _ from 'lodash';

export default handleActions({

  [actions.login]: (state, action) => {
    switch (action.payload) {
      case API_START:
        return { ...state, login: { ...state.login, errors: {}, status: API_LOADING } };
      case API_END:
        return { ...state, login: { ...state.login, status: API_READY } };
      default:
        return state;
    }
  },
  [actions.login_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');
    return { ...state, login: { ...state.login, errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'), success_message: '' } };
  },
  [actions.login_succeeded]: (state, action) => {
    const user = _.pick(action.payload, ['email', 'first_name', 'last_name', 'roles']),
      auth = action.payload.auth.filter(a => a.type === 'jwt');

    if (auth.length) user.access_token = auth[0].auth;

    return { ...state, login: { ...state.login, ...user } };
  },
  [actions.logout]: (state, action) => {
    return { ...state, login: getDefaults().login };
  },
  [actions.request_password_reset]: (state, action) => {
    switch (action.payload) {
      case API_START:
        return { ...state, login: { ...state.login, errors: {} } };
      default:
        return state;
    }
  },
  [actions.request_password_reset_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');
    return { ...state, login: { ...state.login, errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') } };
  },
  [actions.request_password_reset_succeeded]: (state, action) => {
    return { ...state, login: { ...state.login, password_reset_requested: true, email: action.payload.email } }
  },
  [actions.reset_password]: (state, action) => {
    switch (action.payload) {
      case API_START:
        return { ...state, login: { ...state.login, errors: {} } };
      default:
        return state;
    }
  },
  [actions.reset_password_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');
    return { ...state, login: { ...state.login, errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') } };
  },
  [actions.reset_password_succeeded]: (state, action) => {
    return { ...state, login: { ...getDefaults().login, email: action.payload.email, password_reset_success: true }, }
  },
  [actions.redirect_on_reset_success]: (state, action) => {
    return { ...state, login: { ...state.login, password_reset_success: false, success_message: 'Password reset successful!' }, }
  },
  [actions.navigate]: (state, action) => {
    return { ...state, login: { ...state.login, success_message: '' }, }
  },

}, getDefaults());

export function getDefaults() {
  return {
    login: {
      first_name: null,
      last_name: null,
      email: null,
      access_token: null,
      password_reset_requested: null,
      password_reset_success: null,
      success_message: '',
      roles: [],
      errors: {},
    },
    users: [],
    changes: [],
  };
}