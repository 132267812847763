import React from 'react';
import PropTypes from 'prop-types';
import {FormFeedback, Label, FormGroup, ButtonGroup, Button, Input} from 'reactstrap';
import {Field, FastField, useFormikContext} from 'formik';
import _ from 'lodash';

/*Fields*/
import TextInput from './text.input';
import DatePicker from './date.picker';
import DropDownDatePicker from './dropdown.date.picker';
import Select from './select';
import AutoSuggest from './auto.suggest';
import DateMask from './date.mask';

const ConditionalWrapper = ({condition, wrapper, normal, children}) => condition ? wrapper(children) : normal(children);

/*Formik Fields*/
export const FieldSetCheckBox = ({fastfield = false, name, label, link, linkText, error = {}}) => {
    const {values, setFieldValue} = useFormikContext();
    const err = error[name] || null;

    return (

        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name} className='form-check-input'>{children}</FastField>}
            normal={children => <Field name={name} className='form-check-input'>{children}</Field>}
        >
            {({field}) => {

                return <FormGroup check>
                    <Label check className={` ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>
                        <Input type="checkbox"  {...{name: field.name, checked: field.value}} onChange={() => setFieldValue(field.name, !values[field.name])}/>{' '}
                        {label}
                    </Label>
                </FormGroup>;
            }}
        </ConditionalWrapper>
    );
};

export const FieldSet = ({fastfield = false, name, label, readonly, error = {}, type, placeholder, autocomplete, handleOnBlur, children, handleOnChange, innerRef, required, prepend, append, readOnly}) => {

    return (
        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name} type={type}>{children}</FastField>}
            normal={children => <Field name={name} type={type}>{children}</Field>}
        >
            {({field}) => {
                const err = error[name] || null;
                return <TextInput {...{
                    field,
                    name,
                    type,
                    label,
                    readonly,
                    error: err,
                    placeholder,
                    autocomplete,
                    handleOnChange,
                    handleOnBlur,
                    children,
                    innerRef,
                    required,
                    prepend,
                    append,
                    readOnly
                }}/>;
            }}
        </ConditionalWrapper>

    );
};

export const FieldSetDateMask = ({fastfield = false, name, label, readonly, error = {}, placeholder, autocomplete, handleOnBlur, children, handleOnChange, innerRef, required, mask}) => {

    return (
        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name}>{children}</FastField>}
            normal={children => <Field name={name}>{children}</Field>}
        >
            {({field}) => {
                const err = error[name] || null;
                return <DateMask {...{field, name, label, readonly, error: err, placeholder, autocomplete, handleOnChange, handleOnBlur, children, innerRef, required, mask}}/>;
            }}
        </ConditionalWrapper>

    );
};

export const FieldSetAutoSuggest = ({fastfield = false, name, label, placeholder, list, autocomplete, error = {}, required, innerRef, autofocus = false, displayClearButton = false}) => {

    return (
        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name}>{children}</FastField>}
            normal={children => <Field name={name}>{children}</Field>}
        >
            {({field}) => {
                let err = error[field.name] || null;
                return (
                    <AutoSuggest {...{
                        ...field,
                        options: list,
                        handleOnChange: value => field.onChange(name)(value),
                        innerRef,
                        name: field.name,
                        placeholder,
                        error: err,
                        label,
                        autocomplete,
                        autofocus,
                        required,
                        displayClearButton,
                    }}
                    />
                );
            }}
        </ConditionalWrapper>
    );
};

export const FieldSetDatePicker = ({
                                       fastfield = false, name, label, placeholder, autocomplete, error = {}, handleOnBlur, innerRef, minDate, maxDate,
                                       yearDropdownItemNumber,
                                       required = false,
                                       readOnly = false,
                                       dropdownMode,
                                       showYearDropdown,
                                       showMonthDropdown,
                                       displayCurrentDate
                                   }) => {
    return (
        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name}>{children}</FastField>}
            normal={children => <Field name={name}>{children}</Field>}
        >
            {({field}) => {
                let err = error[field.name] || null;
                return (
                    <DatePicker {...{
                        ...field,
                        label,
                        name,
                        placeholder,
                        error: err,
                        autocomplete,
                        minDate,
                        maxDate,
                        innerRef,
                        handleOnBlur,
                        yearDropdownItemNumber,
                        onDateChange: date => field.onChange(name)(date),
                        required,
                        readOnly,
                        dropdownMode,
                        showYearDropdown,
                        showMonthDropdown,
                        displayCurrentDate
                    }}/>

                );
            }}
        </ConditionalWrapper>

    );
};

export const FieldSetDropDownDatePicker = ({fastfield = false, name, label, styles, classess, error = {}, innerRef, displayMonthName, startYear, endYear, order = [], required}) => {
    return (
        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name}>{children}</FastField>}
            normal={children => <Field name={name}>{children}</Field>}
        >
            {({field}) => {
                let err = error[field.name] || null;
                return (
                    <DropDownDatePicker {...{
                        ...field,
                        styles,
                        classess,
                        label,
                        name,
                        selectedDate: field.value,
                        displayMonthName,
                        error: err,
                        startYear: startYear || null,
                        endYear: endYear || null,
                        innerRef,
                        order,
                        required,
                        onDateChange: date => field.onChange(name)(date),
                    }}/>

                );
            }}
        </ConditionalWrapper>

    );
};

export const FieldSetSelect = ({fastfield = false, name, label, error = {}, placeholder, autocomplete: autoComplete, handleOnBlur, options = [], handleOnChange, innerRef, required, autofocus = false, optGroup = false, readOnly = false}) => {
    return (
        <ConditionalWrapper
            condition={fastfield}
            wrapper={children => <FastField name={name} innerRef={innerRef}>{children}</FastField>}
            normal={children => <Field name={name} innerRef={innerRef}>{children}</Field>}
        >
            {({field}) => {
                const err = error[field.name] || null;
                return <Select {...{
                    label,
                    name,
                    innerRef,
                    autoComplete,
                    placeholder,
                    error: err,
                    options,
                    field,
                    autofocus,
                    handleOnBlur,
                    handleOnChange: val => handleOnChange ? handleOnChange(val) : field.onChange(name)(val),
                    required,
                    optGroup,
                    disabled: readOnly
                }}/>;
            }}
        </ConditionalWrapper>
    );
};

export const FieldSetButtonGroup = ({fastfield = false, label, name, options = [], extraOptions = [], innerRef, error = {}, otherKey, required,readOnly = false}) => {
    const {values, setFieldValue} = useFormikContext();
    const fieldValue = _.get(values, name, '');
    const [isIn, setIsIn] = React.useState(fieldValue === otherKey);

    React.useEffect(() => {
        if (extraOptions) {
            const valueIsInOtherOptions = extraOptions.some(opt => opt.key === fieldValue);
            setIsIn(valueIsInOtherOptions || fieldValue === otherKey);
        }
    }, [extraOptions, setIsIn, fieldValue, otherKey]);

    const err = error[name] || null;

    return (
        <FormGroup className={'radio-button-group'}>
            {label && (
                <Label className={`d-block ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>
                    {label}
                    {required && <span className={'field-required text-danger ml-1'}>*</span>}
                </Label>
            )}
            <ButtonGroup className={options.length >= 3 ? 'button-group block' : ''}>
                {options.map((option, idx) => (
                    <ConditionalWrapper
                        key={option.key}
                        condition={fastfield}
                        wrapper={children => <FastField name={name} key={option.key}>{children}</FastField>}
                        normal={children => <Field name={name} key={option.key}>{children}</Field>}
                    >
                        {({field}) =>
                            <Button data-testid={idx > 0 ? `test-${name}-${idx}` : `test-${name}`} color={`${readOnly ? 'outline-secondary' : err ? 'outline-danger' : 'outline-primary'} text-capitalize`}
                                    active={option.key === field.value}
                                    style={{zIndex: 0}}
                                    onClick={() => readOnly ? {}: setFieldValue(field.name, option.key)}
                            >
                                {option.value}
                            </Button>
                        }
                    </ConditionalWrapper>

                ))}
            </ButtonGroup>
            {isIn && !_.isEmpty(extraOptions) && (
                <FieldSetSelect
                    {...{
                        fastfield,
                        label: '',
                        type: 'select',
                        hideLabel: true,
                        options: extraOptions,
                        name,
                        error,
                        innerRef,
                    }}
                />
            )}
            {err && !isIn && (<FormFeedback style={{display: err ? 'block' : 'none'}}
                                            valid={_.isEmpty(err)}>{err}</FormFeedback>)}
        </FormGroup>
    );
};

FieldSetCheckBox.propType = {
    fastfield: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    error: PropTypes.object,
};

FieldSetDropDownDatePicker.propTypes = {
    fastfield: PropTypes.bool,
    label: PropTypes.string,
    startYear: PropTypes.string,
    endYear: PropTypes.string,
    displayMonthName: PropTypes.bool,
    name: PropTypes.string,
    error: PropTypes.object,
    classes: PropTypes.shape({
        wrapper: PropTypes.string,
        day: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
    }),
    styles: PropTypes.shape({
        day: PropTypes.object,
        month: PropTypes.object,
        year: PropTypes.object,
    }),
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
};

FieldSetSelect.propType = {
    fastfield: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        options: PropTypes.array,
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })),
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
    optGroup: PropTypes.bool,
    readOnly: PropTypes.bool
};

FieldSetAutoSuggest.propType = {
    fastfield: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    list: PropTypes.objectOf(PropTypes.string),
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    displayClearButton: PropTypes.bool,
};

FieldSetDatePicker.propType = {
    fastfield: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    yearDropdownItemNumber: PropTypes.number,
    dropdownMode: PropTypes.string,
    showYearDropdown: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    displayCurrentDate: PropTypes.bool

};

FieldSet.propType = {
    fastfield: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    children: PropTypes.node,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
};

FieldSetDateMask.propType = {
    fastfield: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    children: PropTypes.node,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
};

