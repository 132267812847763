import {createAction} from 'redux-actions';
import {apiGet, apiPost} from "./api.actions";

const actions = {
    vehicle_lookup_make: createAction('VEHICLE_LOOKUP_MAKE'),
    vehicle_lookup_model: createAction('VEHICLE_LOOKUP_MODEL'),
    vehicle_lookup_year: createAction('VEHICLE_LOOKUP_YEAR'),
    vehicle_lookup_body_type: createAction('VEHICLE_LOOKUP_BODY_TYPE'),
    vehicle_lookup_transmission: createAction('VEHICLE_LOOKUP_TRANSMISSION'),
    vehicle_lookup_fuel_type: createAction('VEHICLE_LOOKUP_FUEL_TYPE'),
    vehicle_lookup_spec: createAction('VEHICLE_LOOKUP_SPEC'),

    vehicle_lookup_succeeded: createAction('VEHICLE_LOOKUP_SUCCEEDED'),
    vehicle_lookup_failed: createAction('VEHICLE_LOOKUP_FAILED'),

    update_vehicle_details_manually: createAction('VEHICLE_LOOKUP_UPDATE_VEHICLE_DETAILS')
}

export default actions;

export const getMake = ()=> apiGet('api/lookup/vehicle/make', {}, actions.vehicle_lookup_make, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);
export const getModel = data => apiPost('api/lookup/vehicle/model', data, actions.vehicle_lookup_model, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);
export const getYear = data => apiPost('api/lookup/vehicle/year', data, actions.vehicle_lookup_year, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);
export const getBodyType = data => apiPost('api/lookup/vehicle/body-type', data, actions.vehicle_lookup_body_type, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);
export const getTransmission = data => apiPost('api/lookup/vehicle/transmission', data, actions.vehicle_lookup_transmission, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);
export const getFuelType = data => apiPost('api/lookup/vehicle/fuel-type', data, actions.vehicle_lookup_fuel_type, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);
export const getSpec = data => apiPost('api/lookup/vehicle/spec', data, actions.vehicle_lookup_spec, actions.vehicle_lookup_succeeded, actions.vehicle_lookup_failed);