import React from 'react';
import moment from 'moment';
import {FormFeedback, Input, FormGroup, Label, Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

class DropDownDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startYear: props.startYear || moment()
          .subtract(120, 'years')
          .format('YYYY'),
      endYear: props.endYear || moment().format('YYYY'),
      days: [],
      day: '',
      month: '',
      year: '',
      order: props.order.length > 0 ? props.order : ['year', 'month', 'day'],
    };
    this.renderParts = {
      year: this.renderYear,
      month: this.renderMonth,
      day: this.renderDay,
    };
  }

  getInitialDate = type => {
    let out = '';
    switch (type) {
      case 'year':
        out = moment().format('YYYY');
        break;
      case 'month':
        out = moment().format('MM');
        break;
      case 'day':
        out = '01';
        break;
      default:
        break;
    }
    return out;
  };

  mapDate = date => {
    const format = date ? date.match(/^\d{4}$/) ? 'YYYY' : (date.match(/^\d{2}\/\d{4}$/) ? 'MM-YYYY' : 'DD-MM-YYYY') : 'DD-MM-YYYY';
    const [day, month, year] = moment(date ? date : moment(), format).format('DD-MM-YYYY').split('-');
    const _date = {day, month, year};
    const {order} = this.state;

    Object.keys(_date).forEach(part => {
      _date[part] = order.indexOf(part) !== -1 ? _date[part] : this.getInitialDate(part);
    });

    return _date;
  };

  componentDidMount() {
    const {day, month, year} = this.mapDate(this.props.selectedDate);
    this.setState(
        {
          day,
          month,
          year,
        },
        () => this.generateDays(year, month),
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {startYear, endYear, selectedDate} = this.props;
    const {
      startYear: prevStartYear,
      endYear: prevEndYear,
      selectedDate: prevSelectedDate,
    } = prevProps;

    if (selectedDate && prevSelectedDate !== selectedDate) {

      const {day, month, year} = this.mapDate(selectedDate);
      if (day && month && year) {
        this.setState(
            {
              day,
              month,
              year,
            },
            () => this.generateDays(year, month),
        );
      }
    }
    if (prevStartYear !== startYear) {
      this.setState({
        startYear,
      });
    }
    if (prevEndYear !== endYear) {
      this.setState({
        endYear,
      });
    }
  }

  generateYears = () => {
    const {startYear, endYear} = this.state;
    const years = [];

    for (let i = startYear; i <= endYear; i++) {
      years.push({value: i, year: i});
    }
    return years;
  };

  str_pad = n => String('00' + n).slice(-2);

  generateMonths = () => {
    let months = moment.monthsShort();

    months = months.map((m, i) => {
      return {value: this.str_pad(i + 1), month: m};
    });
    return months;
  };

  handleDateChange = () => {
    const {onDateChange} = this.props;

    if (typeof onDateChange === 'function' && onDateChange) {
      const {day, month, year, order} = this.state;
      const _day = order.indexOf('day') !== -1 && day ? `${day}/` : '';
      const _month = order.indexOf('month') !== -1 && month ? `${month}/` : '';
      const _year = order.indexOf('year') !== -1 && year ? `${year}` : '';
      const date = (_day + _month + _year).trim();

      onDateChange(date);
    }
  };

  generateDays = (year, month) => {
    const today = moment(),
        _days = moment(
            `${year || today.format('YYYY')}-${month || today.format('MM')}`,
            'YYYY-MM',
        ).daysInMonth(),
        days = [];

    for (let i = 1; i <= _days; i++) {
      days.push({value: this.str_pad(i), day: i});
    }
    this.setState({days}, () => this.handleDateChange());
  };

  dateIsValid = (day, month, year) => moment(`${day}.${month}.${year}`, 'DD.MM.YYYY').isValid();

  handleUpdateDate = (newDate) => {
    this.setState(prevState => {
      return {
        ...prevState,
        ...newDate,
      };
    }, () => this.generateDays(this.state.year, this.state.month));
  };

  renderMonth = () => {
    const {state: {day, month, year}, props: {styles, displayMonthName, error}, handleUpdateDate, generateMonths, dateIsValid} = this;
    const err = error || null;
    const months = generateMonths();
    return (
        <Input
            type="select"
            name="month"
            id="month"
            value={month}
            style={styles && styles.month ? styles.month : {}}
            invalid={!_.isEmpty(err)}
            onChange={e => handleUpdateDate({month: e.target.value, day: dateIsValid(day, e.target.value, year) ? day : ''})}
        >
          <option value="" hidden>
            month
          </option>
          {months.map(m => (
              <option key={m.value} value={m.value}>
                {displayMonthName ? m.month : m.value}
              </option>
          ))}
        </Input>
    );
  };

  renderYear = () => {
    const {state: {day, month, year}, props: {styles, innerRef, error}, handleUpdateDate, generateYears, dateIsValid} = this;
    const err = error || null;
    const years = generateYears();
    return (
        <Input
            type="select"
            name="year"
            id="year"
            value={year}
            style={styles && styles.year ? styles.year : {}}
            onChange={e => handleUpdateDate({year: e.target.value, day: dateIsValid(day, month, e.target.value) ? day : ''})}
            autoFocus={true}
            invalid={!_.isEmpty(err)}
            innerRef={innerRef}
        >
          >
          <option value="" hidden>
            year
          </option>
          {years.map(y => (
              <option key={y.value} value={y.value}>
                {y.value}
              </option>
          ))}
        </Input>
    );
  };

  renderDay = () => {
    const {state: {days, day}, props: {name, styles, error}, handleDateChange} = this;
    const err = error || null;

    return (
        <Input
            data-testid={`test-${name}`}
            type="select"
            name="day"
            id="day"
            value={day}
            style={styles && styles.day ? styles.day : {}}
            invalid={!_.isEmpty(err)}
            onChange={e =>
                this.setState({day: e.target.value}, () => handleDateChange())
            }
        >
          <option value="" hidden>
            day
          </option>
          {days.map(y => (
              <option key={y.value} value={y.value}>
                {y.value}
              </option>
          ))}
        </Input>
    );
  };

  render() {
    const {error, name, classes, label, required} = this.props,
        {renderParts} = this,
        {order} = this.state,
        err = error || null;

    return (
        <FormGroup className={`drop-down-date-picker-container ${err ? 'is-invalid' : ''} ${classes && classes.wrapper ? classes.wrapper : ''}`}>
          {
            label && (
                <Label htmlFor={name} className={` ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>
                  {label}
                  {required && <span className={'field-required text-danger ml-1'}>*</span>}
                </Label>

            )
          }
          <Row className={`drop-down-date-picker-wrapper ${err ? 'is-invalid' : ''} ${classes && classes.wrapper ? classes.wrapper : ''}`}>
            {order.map(part => (
                renderParts[part] ?
                    <Col className={`drop-down-date-picker drop-down-date-picker ${classes && classes[part] ? classes[part] : ''}`} key={part}>
                      {renderParts[part]()}
                    </Col> : null
            ))}
            {err && (
                <FormFeedback
                    className="m-1 p-1 "
                    style={{display: err ? 'block' : 'none'}}
                    invalid={err}
                >
                  {err}
                </FormFeedback>
            )}
          </Row>
        </FormGroup>
    );
  }
}

DropDownDatePicker.propTypes = {
  label: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  displayMonthName: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.any,
  selectedDate: PropTypes.string,
  order: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
  styles: PropTypes.shape({
    day: PropTypes.object,
    month: PropTypes.object,
    year: PropTypes.object,
  }),
  onDateChange: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any}),
  ]),
};
DropDownDatePicker.defaultProps = {
  order: ['year', 'month', 'day'],
  displayMonthName: false,
};

export default DropDownDatePicker;