import axios from 'axios';
import actions from '../actions/api.actions';

import {apiError, apiStart, apiEnd, apiSuccess} from '../actions/api.actions';

const apiMiddleware = ({dispatch}) => next => action => {
  if (action.type !== actions.api) return next(action);
  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    headers,
  } = action.payload;
  let {
    label,
  } = action.payload;

  if (label) typeof label === 'function' ? dispatch(label(actions.api_start)) : dispatch(apiStart(label));

  axios
      .request({
        url,
        method,
        headers: {'content-type': 'application/json', ...headers},
        [['GET'].includes(method) ? 'params' : 'data']: data,
      })
      .then(({data}) => {
        if (label) typeof label === 'function' ? dispatch(label(actions.api_success)) : dispatch(apiSuccess(label));
        dispatch(onSuccess(data));
      })
      .catch(({response}) => {
        if (label) typeof label === 'function' ? dispatch(label(actions.api_error)) : dispatch(apiError(label));
        dispatch(onFailure(response));
      })
      .finally(() => {
        if (label) typeof label === 'function' ? dispatch(label(actions.api_end)) : dispatch(apiEnd(label));
      });
};

export default apiMiddleware;