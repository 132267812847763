import React from 'react';
import {Spinner} from 'reactstrap';

const Loader = ({message}) => {
  return (
      <div className={'loader text-center'}>
        <div className={'content'}>
          <div className='m-5'>
            <Spinner/>
          </div>
          <div className={'h2 m-5'}>{message}</div>
        </div>
      </div>
  );
};

export default Loader;