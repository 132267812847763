import React, {Suspense} from 'react';
import './app.scss';
import {Route, Switch, Redirect} from "react-router-dom";
import Loader from "./components/widgets/loader";
import requiredAuth from './components/admin/page.authorised';

const Admin = React.lazy(() => import('./containers/admin.container'));
const AdminPassword = React.lazy(() => import('./containers/admin.password.container'));
const Users = React.lazy(() => import( './containers/users.container'));
const Default = React.lazy(() => import( './components/default'));
const Form = React.lazy(()=> import('./containers/form.container'));

function App() {
    return (
        <div className="App">
            <Suspense fallback={<Loader message={'Loading...'}/>}>
                <Switch>
                    {/*full form routes*/}
                    <Route exact path="/">
                        <Redirect to="/admin"/>
                    </Route>
                    {/* Admin Pages */}
                    <Route exact path='/admin' component={requiredAuth(Admin)}/>
                    <Route path='/admin/users' component={requiredAuth(Users)}/>
                    <Route path='/admin/reset-password' component={AdminPassword}/>

                    {/*Form page*/}
                    <Route exact path='/form/:uuid?' component={requiredAuth(Form)}/>
                    <Route component={Default}/>
                </Switch>
            </Suspense>
        </div>
    );
}

export default App;
