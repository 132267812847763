const isProduction = () => process.env.NODE_ENV === 'production';
export {isProduction};

export default {
    form:{
        modifications: 20,
        securities: 20,
        accessories: 20,
        drivers: 5,
        occupations: 2,
        convictions: 20,
        claims: 20,
        medical: 20,
    },
    backend_url: isProduction() ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL
}