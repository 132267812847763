import {createAction} from 'redux-actions';
import {apiGet, apiPost, apiDelete} from './api.actions';

const actions = {
    send_request: createAction('USERS_SEND_REQUEST'),
    send_request_failed: createAction('USERS_SEND_REQUEST_FAILED'),


    create_succeeded: createAction('USERS_CREATE_SUCCEEDED'),

    force_delete_user_succeeded: createAction('FORCE_DELETE_USER_SUCCEEDED'),

    add_role_succeeded: createAction('USERS_ADD_ROLE_SUCCEEDED'),

    delete_role_succeeded: createAction('USERS_DELETE_ROLE_SUCCEEDED'),

    update_roles_succeeded: createAction('USERS_UPDATE_ROLES_SUCCEEDED'),

    get_users_succeeded: createAction('USERS_GET_ALL_SUCCEEDED'),
};
export default actions;

export const onCreateUser = (data) => {
    const {auth: _auth, ...rest} = data;
    const auth = `JWT ${_auth}`
    return apiPost(`admin/user`, rest, actions.send_request, actions.create_succeeded, actions.send_request_failed, {Authorization: auth});
};

export const onAddRole = (data) => {
    const {auth: _auth, ...rest} = data;
    const auth = `JWT ${_auth}`
    return apiPost(`admin/role`, rest, actions.send_request, actions.add_role_succeeded, actions.send_request_failed, {Authorization: auth});
};

export const onGetUsers = (data) => {
    const {auth: _auth, ...rest} = data;
    const auth = `JWT ${_auth}`
    return apiGet(`admin/users`, rest, actions.send_request, actions.get_users_succeeded, actions.send_request_failed, {Authorization: auth});
};

export const onDeleteRole = (data) => {
    const {auth: _auth, ...rest} = data;
    const auth = `JWT ${_auth}`
    return apiDelete(`admin/role`, rest, actions.send_request, actions.delete_role_succeeded, actions.send_request_failed, {Authorization: auth});
};

export const onUpdateRoles = data =>{
    const {auth: _auth, ...rest} = data;
    const auth = `JWT ${_auth}`
    return apiPost(`admin/update-roles`, rest, actions.send_request, actions.update_roles_succeeded, actions.send_request_failed, {Authorization: auth});
};

export const onForceDeleteUser = (data) => {
    const {auth: _auth, ...rest} = data;
    const auth = `JWT ${_auth}`
    return apiDelete(`admin/user/force-delete`, rest, actions.send_request, actions.force_delete_user_succeeded, actions.send_request_failed, {Authorization: auth});
};

