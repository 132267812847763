
const redirect = {
    // to be used in a reducer
    add: (to) => ({redirect_path: to}),
    // to be used in a react component
    handle: (props = {}) => {
        const {redirect_path, history} = props,
            {pathname} = props.location;
        if(!redirect_path) return;
        // check current path and redirect only if different
        if(redirect_path && redirect_path !== pathname){
            history.push(redirect_path);
        }

    }
};
export default redirect;