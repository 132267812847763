import _ from 'lodash';
import actions from '../actions/form.actions';
import api_actions from '../actions/api.actions';
import {handleActions} from "redux-actions";
import redirect from "../../utils/redirect";

const updateUuidObject = (_state, action, new_data, _uuid = null) => {
    const state = {..._state};
    const uuid = _.get(action, 'payload.data.uuid', '') || _uuid;

    if (!uuid) return state;
    if (!state[uuid]) state[uuid] = {};

    state[uuid] = {...state[uuid], ...new_data};

    return state;
};

export default handleActions({
    [actions.form_get_data]: (state, action) => {
        const {uuid} = action;
        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {isLoading: true, errors: {}}, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {isLoading: false}, uuid);
            default:
                return state;
        }
    },
    [actions.form_succeeded]: (state, action) => {
        const {uuid, initialValues, schema, defaults} = action.payload;
        const _uuid = uuid || action.uuid;
        const redirect_path = _uuid ? `/form/${_uuid}` : '';
        return {
            ...updateUuidObject(state, action, {
                data: initialValues,
                isLoading: false,
                defaults,
                schema
            }, _uuid),
            ...redirect.add(redirect_path)
        };
    },
    [actions.form_submit_succeeded]: (state, action) => {
        const { uuid, values: data } = action;
        
        return {
            ...updateUuidObject(state, action, {
                data,
               granite: action.payload
            }, uuid)
        };
    },
    [actions.form_failed]: (state, action) => {
        const {uuid, values: data} = action;
        const errors = _.get(action, 'payload.data.errors');

        const graniteErrors = _.get(action, 'payload.data.errors.error', '');

        return updateUuidObject(state, action, {
            data,
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') || 'Please try later',
            graniteErrors,
            isLoading: false
        }, uuid);
    },

    [actions.add]: (state, action) => {
        const {fields, uuid, formValues, defaultValues} = action.payload;
        let _values = _.cloneDeep(formValues);
        if (!uuid || !fields) return state;

        fields.forEach(f => _.set(_values, f, _.get(defaultValues, f,'')));

        return updateUuidObject(state, action, {data: {...formValues, ..._values}}, uuid);
    },
    [actions.remove]: (state, action) => {
        const {fields, uuid, formValues} = action.payload,
            _values = _.cloneDeep(formValues),
            {1: main, 2: mainIndex, 3: partial, 4: partialIndex} = fields[0].match(/^([\w_]+)\.(\d{1,2})\.([\w_]+)\.?(\d{1,2})?\.?[\w_]+?$/);

        if (!uuid || !fields) return state;

        const path = partialIndex ? main.concat('.', mainIndex, '.', partial) : main,
            index = partialIndex ? partialIndex : mainIndex,
            refs = _.get(_values, path);

        refs.splice(index, 1);
        _.set(_values, path, refs);

        return updateUuidObject(state, action, {data: {..._values}}, uuid);
    },
    [actions.postcode_search]: (state, action) => {
        const {uuid, key} = action;
        const _state = _.get(state, uuid, {});

        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    postcodeIsLoading: {..._state.postcodeIsLoading, [key]: true},
                    postcodeErrors: {..._state.postcodeErrors, [key]: {}},
                    postcodeData: {..._state.postcodeData, [key]: {}},
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {postcodeIsLoading: {..._state.postcodeIsLoading, [key]: false}}, uuid);
            default:
                return state;
        }
    },
    [actions.postcode_select]: (state, action) => {
        const {uuid, key} = action;
        const _state = _.get(state, uuid, {});
        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    postcodeData: {..._state.postcodeData, [key]: {..._state.postcodeData[key], address: {}}},
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {}, uuid);
            default:
                return state;
        }
    },
    [actions.postcode_search_succeeded]: (state, action) => {
        const {uuid, key} = action;
        const mappedPostcodes = action.payload.map(p => {
            const {StreetAddress, Id} = p;
            return {key: Id, value: StreetAddress};
        });

        const _state = _.get(state, uuid, {});
        return updateUuidObject(state, action, {postcodeData: {..._state.postcodeData, [key]: {..._state.postcodeData[key], mappedPostcodes}}}, uuid);
    },
    [actions.postcode_select_succeeded]: (state, action) => {
        const {uuid, key} = action;
        const {Line1, Line2, Line3, PrimaryStreet, County, Postcode, PostTown, SubBuilding, BuildingName, BuildingNumber} = _.pick(action.payload[0], ['Line1', 'Line2', 'Line3', 'PrimaryStreet', 'County', 'Postcode', 'PostTown', 'SubBuilding', 'BuildingName', 'BuildingNumber']);

        const street = [SubBuilding, BuildingName, BuildingNumber, PrimaryStreet].join(' ').trim();

        const _state = _.get(state, uuid, {});
        const address = {
            house: [BuildingNumber, Line1, Line2, Line3].join(' ').trim() || '',
            locality: County,
            street:  street || '',
            city: PostTown,
            postcode: Postcode
        };

        return updateUuidObject(state, action, {postcodeData: {..._state.postcodeData, [key]: {..._state.postcodeData[key], address}}}, uuid);
    },
    [actions.postcode_failed]: (state, action) => {
        const {uuid, key} = action;
        const _errors = _.get(action, 'payload.data.errors');

        let errors = {[key]: !_.isObject(_errors) ? _errors : _.values(_errors)[0]};
        errors = !_.isEmpty(errors) ? errors : 'Our address lookup service is down, please enter manually';
        const _state = _.get(state, uuid, {});
        return updateUuidObject(state, action, {postcodeErrors: {..._state.postcodeErrors, ...errors}}, uuid);
    },

}, getDefaults());


export function getDefaults() {
    return {};
}
