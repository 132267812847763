import React from 'react';
import {FormFeedback, FormGroup, Input, Label, InputGroup, InputGroupText, InputGroupAddon} from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';

const TextInput = ({label, field, error, name, handleOnChange, handleOnBlur, innerRef, type, placeholder, autocomplete, readOnly, children, value, required, focus, prepend, append}) => {
  return (
      <FormGroup>
        {
          label && <Label htmlFor={name}
                          className={` ${!_.isEmpty(error) ? 'is-invalid' : ''} `}>
            {label}
            {
              required && <span className={'field-required text-danger ml-1'}>*</span>
            }
          </Label>
        }
        <InputGroup>
          {
            prepend && <InputGroupAddon addonType='prepend'><InputGroupText>{prepend}</InputGroupText></InputGroupAddon>
          }
          <Input
              {...{
                ...field && field,
                ...handleOnChange && {onChange: handleOnChange},
                ...handleOnBlur && {onBlur: handleOnBlur},
                ...value && value,
                'data-testid': `test-${name}`,
                'data-hj-whitelist': 'data-hj-whitelist',
                id: name,
                innerRef,
                //autoFocus: focus || true,
                type,
                placeholder,
                name,
                invalid: !_.isEmpty(error),
                autoComplete: autocomplete,
                readOnly,
              }}>
            {children}
          </Input>
          {
            append && <InputGroupAddon addonType='append'><InputGroupText>{append}</InputGroupText></InputGroupAddon>
          }
        </InputGroup>
        {error && (<FormFeedback valid={_.isEmpty(error)} style={{display: _.isEmpty(error)? 'none' : 'block'}}>{error}</FormFeedback>)}
      </FormGroup>
  );
};
TextInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  error: PropTypes.any,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any}),
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  readOnly: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.any,
};

export default TextInput;