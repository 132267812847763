import {createStore, combineReducers, applyMiddleware} from "redux";

import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import vehicleLookupActions from './actions/vehicle.lookup.actions';

import adminReducer from './reducers/admin.reducer';
import usersReducer from './reducers/users.reducer';
import formReducer from './reducers/form.reducer';
import vehicleLookupReducer, {handleUpdateVehicleDetailsManually} from './reducers/vehicle.lookup.reducer';

import apiMiddleware from './middlewares/api.middleware';

const middlewares = [thunk, apiMiddleware];


const combinedReducers = combineReducers({
    admin: adminReducer,
    user: usersReducer,
    form: formReducer,
    vehicleLookup: vehicleLookupReducer
});

const mixedReducers = (state, action) => {
    switch (action.type) {
        case vehicleLookupActions.update_vehicle_details_manually().type:
            return handleUpdateVehicleDetailsManually(state, action);
        default:
            return state;
    }
};


const rootReducers = (state, action) => mixedReducers(combinedReducers(state, action), action);

const persistedReducers = persistReducer({
        key: 'granite-backend-standalone',
        storage,
        transforms: [],
        whitelist: ['admin'],
    },
    rootReducers,
);

function clearStorage() {
    //todo
}


const store = createStore(persistedReducers, {}, composeWithDevTools(applyMiddleware(...middlewares)));
let persistor = persistStore(store);
export {store, persistor, clearStorage};