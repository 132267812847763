import {createAction} from 'redux-actions';
import {apiGet, apiPost} from "./api.actions";
import _ from "lodash";

const actions = {
    form_get_data: createAction('FORM_GET_DATA'),
    form_succeeded: createAction('FORM_SUCCEEDED'),
    form_failed: createAction('FORM_FAILED'),

    form_submit_succeeded: createAction('FORM_SUBMIT_SUCCEEDED'),

    add: createAction('FROM_ADD_ITEM'),
    remove: createAction('FROM_REMOVE_ITEM'),

    postcode_search: createAction('POSTCODE_SEARCH'),
    postcode_select: createAction('POSTCODE_SELECT'),
    postcode_search_succeeded: createAction('POSTCODE_SEARCH_SUCCEEDED'),
    postcode_select_succeeded: createAction('POSTCODE_SELECT_SUCCEEDED'),
    postcode_failed: createAction('POSTCODE_FAILED'),
}

export default actions;

export const formGetData = (payload, uuid) => {
    return {
        type: actions.form_get_data.toString(),
        uuid,
        payload
    };
};

const formSucceeded = (payload, uuid) => {
    return {
        type: actions.form_succeeded.toString(),
        uuid,
        payload
    };

};

const formSubmitSucceeded = (payload, uuid, values) => {
    return {
        type: actions.form_submit_succeeded.toString(),
        uuid,
        values,
        payload
    };

};
const formFailed = (payload, uuid, values) => {
    return {
        type: actions.form_failed.toString(),
        uuid,
        values,
        payload
    };
};

const postcodeSearch = (response, uuid, key) => {
    return {
        type: actions.postcode_search.toString(),
        uuid,
        payload: response,
        key
    };
};
const postcodeSelect = (response, uuid, key) => {
    return {
        type: actions.postcode_select.toString(),
        uuid,
        payload: response,
        key
    };
};
const postcodeSearchSucceeded = (response, uuid, key) => {
    return {
        type: actions.postcode_search_succeeded.toString(),
        uuid,
        payload: response,
        key
    };
};
const postcodeSelectSucceeded = (response, uuid, key) => {
    return {
        type: actions.postcode_select_succeeded.toString(),
        uuid,
        payload: response,
        key
    };
};
const postcodeFailed = (response, uuid, key) => {
    return {
        type: actions.postcode_failed.toString(),
        uuid,
        payload: response,
        key
    };
};


export const getFormData = data => {
    return apiGet(`form`,
        data,
        (res) => formGetData(res, data?.uuid),
        (res) => formSucceeded(res, data?.uuid),
        (res) => formFailed(res, data?.uuid));
}

export const addField = payload => {
    return {
        type: actions.add.toString(),
        payload,
    };
};
export const removeField = payload => {
    return {
        type: actions.remove.toString(),
        payload,
    };
};

export const submit = (uuid, values) => {
    return apiPost('form',
        {...values, uuid},
        (res) => formGetData(res, uuid),
        (res) => formSubmitSucceeded(res, uuid, {...values}),
        (res) => formFailed(res, uuid, {...values}));
}

export const searchPostcode = (uuid, postcode) => {
    const key = _.keys(postcode)[0];
    return apiPost('api/lookup/address/search',
        {postcode: _.values(postcode)[0]},
        (res) => postcodeSearch(res, uuid, key),
        (res) => postcodeSearchSucceeded(res, uuid, key),
        (res) => postcodeFailed(res, uuid, key))
};

export const selectPostcode = (uuid, id) => {
    const key = _.keys(id)[0];
    return apiPost('api/lookup/address/select',
        {id: _.values(id)[0]},
        (res) => postcodeSelect(res, uuid, key),
        (res) => postcodeSelectSucceeded(res,  uuid, key),
        (res) => postcodeFailed(res,  uuid, key))
};