import config from '../../config.js'
import {createAction} from "redux-actions";

const actions = {
  api: createAction("API"),
  api_start:  createAction('API_START'),
  api_success:  createAction('API_SUCCESS'),
  api_end:  createAction('API_END'),
  api_access_denied:  createAction('API_ACCESS_DENIED'),
  api_error:  createAction('API_ERROR')
};
export default actions;

export const apiStart = label => {
  return ({
    type: actions.api_start,
    payload: label,
  });
};

export const apiSuccess = label => ({
  type: actions.api_success,
  payload: label,
});

export const apiEnd = label => ({
  type: actions.api_end,
  payload: label,
});

export const accessDenied = url => ({
  type: actions.api_access_denied,
  payload: {
    url,
  },
});

export const apiError = error => ({
  type: actions.api_error,
  error,
});

export const apiGet = (path = '',
  data,
  label,
  onSuccess = () => ({}),
  onFailure = () => ({}),
                       headers=null
  //todo accessToken, headers, headersOverride
) => apiRequest({
  url: `${config.backend_url}/${path}`,
  method: 'GET',
  data,
  label,
  headers,
  onSuccess,
  onFailure,
});

export const apiPost = (path = '',
  data,
  label,
  onSuccess = () => ({}),
  onFailure = () => ({}),
  headers,
  //todo accessToken, headers, headersOverride
) => {
  return apiRequest({
    url: `${config.backend_url}/${path}`,
    method: 'POST',
    data,
    label,
    onSuccess,
    onFailure,
    headers,
  });
};

export const apiDelete = (path = '',
                          data,
                          label,
                          onSuccess = () => ({}),
                          onFailure = () => ({}),
                          headers=null,
                          //todo accessToken, headers, headersOverride
) => {
  return apiRequest({
    url: `${config.backend_url}/${path}`,
    method: 'DELETE',
    data,
    headers,
    label,
    onSuccess,
    onFailure,
  });
};

export const apiRequest = ({
  url = '',
  method = '',
  data = null,
  accessToken = null,
  onSuccess = () => {
  },
  onFailure = () => {
  },
  label = '',
  headers = null,
  headersOverride = null,
}) => {
  return {
    type: actions.api,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headers,
      headersOverride,
    },
  };
};