import { createAction } from 'redux-actions';
import { apiPost } from './api.actions';

const actions = {
  login: createAction('ADMIN_LOGIN'),
  login_failed: createAction('ADMIN_LOGIN_FAILED'),
  login_succeeded: createAction('ADMIN_LOGIN_SUCCEEDED'),

  logout: createAction('ADMIN_LOGOUT'),

  request_password_reset: createAction('ADMIN_PASSWORD_RESET_REQUEST'),
  request_password_reset_failed: createAction('ADMIN_PASSWORD_RESET_REQUEST_FAILED'),
  request_password_reset_succeeded: createAction('ADMIN_PASSWORD_RESET_REQUEST_SUCCEEDED'),

  reset_password: createAction('ADMIN_PASSWORD_RESET'),
  reset_password_failed: createAction('ADMIN_PASSWORD_RESET_FAILED'),
  reset_password_succeeded: createAction('ADMIN_PASSWORD_RESET_SUCCEEDED'),

  redirect_on_reset_success: createAction('ADMIN_RESET_SUCCESS_REDIRECT'),

  navigate: createAction('ADMIN_NAVIGATE')
};
export default actions;

export const login = (data) => apiPost('admin/login', data, actions.login, actions.login_succeeded, actions.login_failed);

export const requestPasswordReset = (data) => apiPost('admin/request-password-reset', data, actions.request_password_reset, actions.request_password_reset_succeeded, actions.request_password_reset_failed);

export const resetPassword = (data) => apiPost('admin/reset-password', data, actions.reset_password, actions.reset_password_succeeded, actions.reset_password_failed);