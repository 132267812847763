import React from 'react';
import Picker from 'react-datepicker';
import moment from 'moment';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import PropTypes from 'prop-types';

const DatePicker = ({name, value, error, autocomplete, placeholder, label, handleOnBlur, innerRef, onDateChange, showYearDropdown, yearDropdownItemNumber, minDate, maxDate, required, readOnly, dropdownMode, showMonthDropdown, displayCurrentDate = false}) => {

    const [err, setError] = React.useState(null);

    const defaultValue = displayCurrentDate ?  new Date() : '';

    React.useEffect(() => {
        if (err !== error) {
            setError(error || null);
        }
    }, [error, err]);

    React.useEffect(()=>{
        if(!value){
            onDateChange('')
        }
    },[])

    return (
        <FormGroup>
            {
                label && <Label htmlFor={name} className={` ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>{label} {required &&
                <span className={'field-required text-danger ml-1'}>*</span>}</Label>
            }
            <Picker
                readOnly={readOnly}
                className={`form-control`}
                placeholderText={placeholder}
                autoComplete={autocomplete || 'off'}
                id={name}
                dateFormat={'yyyy-MM-dd'}
                selected={value ? (moment(value, 'YYYY-MM-DD').isValid() && moment(value, 'YYYY-MM-DD').toDate()) : defaultValue}
                onChange={val => onDateChange(moment(val).format('YYYY-MM-DD'))}
                dateFormatCalendar="MMMM"
                dropdownMode={dropdownMode}
                showYearDropdown={showYearDropdown}
                showMonthDropdown={showMonthDropdown}
                yearDropdownItemNumber={yearDropdownItemNumber}
                scrollableYearDropdown
                onBlur={handleOnBlur}
                minDate={minDate ? new Date(minDate) : null}
                maxDate={maxDate ? new Date(maxDate) : null}
                customInput={<Input {...{'data-testid': `test-${name}`, 'data-hj-whitelist': 'data-hj-whitelist'}} invalid={!_.isEmpty(err)} innerRef={innerRef}/>}
            />
            {err && (<FormFeedback style={{display: err ? 'block' : 'none'}} valid={_.isEmpty(err)}>{err}</FormFeedback>)}
        </FormGroup>

    );

};
DatePicker.defaultProps = {
    readOnly: false,
    displayCurrentDate: false,
    dropdownMode: '',
    showYearDropdown: false,
    showMonthDropdown: false,
    yearDropdownItemNumber: 0
};

DatePicker.propTypes = {
    autocomplete: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    handleOnBlur: PropTypes.func,
    onDateChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    readOnly: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    dropdownMode: PropTypes.string,
    yearDropdownItemNumber: PropTypes.number,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
    displayCurrentDate: PropTypes.bool
};

export default DatePicker;

